@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;

// Grids (choose one)
@include foundation-xy-grid-classes;
// @include foundation-grid;
// @include foundation-flex-grid;

// Generic components
@include foundation-button;
@include foundation-button-group;
@include foundation-close-button;
@include foundation-label;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-switch;
@include foundation-table;
// Basic components
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-card;
@include foundation-dropdown;
@include foundation-pagination;
@include foundation-tooltip;

// Containers
@include foundation-accordion;
@include foundation-media-object;
@include foundation-orbit;
@include foundation-responsive-embed;
@include foundation-tabs;
@include foundation-thumbnail;
// Menu-based containers
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-accordion-menu;
@include foundation-drilldown-menu;
@include foundation-dropdown-menu;

// Layout components
@include foundation-off-canvas;
@include foundation-reveal;
@include foundation-sticky;
@include foundation-title-bar;
@include foundation-top-bar;

// Helpers
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
// @include foundation-prototype-classes;

// Motion UI
@include motion-ui-transitions;
@include motion-ui-animations;




body {
  background: url(../img/main-bg.jpg) no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  
  width: 100%;
  height: 100%;
}


.box-details {
  margin: auto;
  position: absolute;
  top: 0; 
  left: 0; 
  bottom: 0; 
  right: 0;
  background: rgba(255,255,255,.8);
  width: 35%;
  height: 580px;
  padding: 30px;
  
  img {
    display: block;
    margin: 10px auto 20px auto;
  }
  
  h2 {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 30px;
    text-align: center;
    text-transform: uppercase;
    margin: 0;
    padding: 0 0 20px;
  }
  
  h3 {
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    font-size: 16px;
    margin: 0;
    padding: 0;
  }
  
  
  p {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 14px;
    
    strong {
      font-weight: 500;
    }
  }
  
  ul {
    list-style: none;
    margin: 0;
    padding: 0 0 20px;
    
    li {
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      font-size: 14px;
      
      strong {
        font-weight: 500;
      }
    }
    
    a {
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      font-size: 14px;
      color: #000;
      text-decoration: underline;
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 639px) { 
  .box-details { width: 90%; }
}


@media screen and (min-width: 640px) and (max-width: 1023px) { 
  .box-details { width: 70%; }
}

@media screen and (min-width: 1024px) and (max-width: 1200px) { 
  .box-details { width: 70%; }
}